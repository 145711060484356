import React, { FC } from 'react';
import { Seo, PageLayout } from '../../components';
import { navigate, PageProps } from 'gatsby';
import config from '../../../app-config.json';
import { Grid, Typography } from '@material-ui/core';
import { PAYMENT_FORM_LINK } from './payment-form';
import { BodyWrap, _StyledBtn1 } from "../taher-scheme";
import { siteMetadata } from '../../../gatsby-config';
// import * as s from '../../style.pages/index.style';

const PAGE_TITLE = config.pages.MOHAMMADI_SCHEME.title;
// const PAGE_PATH = config.pages.MOHAMMADI_SCHEME.path;
export const MetaDescription = `This Scheme focuses on contributions made by Mumineen, which is collected on significant dates (e.g. on Lailat al-jumu’ah and yaum al-jumu’ah) and on various mawaaqeet (e.g. Asharah Mubarakah, Eid, Urus or Milad of Hudaat Kiraam RA, etc). The ${PAGE_TITLE} will be perpetual in nature, i.e. once the amount is contributed the amount becomes part of the corpus of the relevant Qardan Hasana Scheme.`


//////////////////////////////////  component ////////////////////////////////
const MuhammadiScheme: FC<PageProps> = ({ }) => {
    return (
        <PageLayout>
            <Seo
                title={`${PAGE_TITLE} | ${siteMetadata.title}`}
                propMetaDescription={MetaDescription}
            />
            <BodyWrap>
                <br />
                <Typography variant='h2' color='inherit' >{PAGE_TITLE}</Typography>
                <Typography color='inherit' variant='h6' gutterBottom >(Non-Refundable)</Typography>
                <hr /><br /><br />
                <Typography align='justify' >This Scheme focuses on contributions made by Mumineen, which is collected on significant dates (e.g. on Lailat ul Jumoa, Yawm ul Jumoa) and on various mawaaqeet (e.g. Ashara Mubaraka, Eid, Urus or Milad of Hudaat Kiraam RA, etc).</Typography>
                <br />
                <Typography align='justify' >The {PAGE_TITLE} will be perpetual in nature, i.e. once the amount is contributed the amount becomes part of the corpus of the relevant Qardan Hasana Scheme.</Typography>
                <br /><br />
                <Grid container justify='center' alignItems='center' >
                    <_StyledBtn1 onClick={() => { navigate(PAYMENT_FORM_LINK) }} >
                        Contribute Towards {PAGE_TITLE}
                    </_StyledBtn1>
                </Grid>
            </BodyWrap>
        </PageLayout>
    )
}

export default MuhammadiScheme;
